import axiosInstance from './axiosConfig';

export const getAutocompletion = async (text, useRag = false, isManual = false, clinicalHistory = '', historicalReport = '') => {
  try {
    const response = await axiosInstance.post('/radcopilot/autocomplete/', { 
      text,
      use_rag: useRag,
      is_manual: isManual,
      clinical_history: clinicalHistory,
      historical_report: historicalReport
    });

    // Log text input
    console.log('Autocomplete text input:', text);    
    // Log response
    console.log('Autocomplete response:', response.data.suggestion);
    console.log('Is manual request:', isManual);

    return response.data.suggestion;
  } catch (error) {
    console.error('Error getting autocompletion:', error.message);
    if (error.response) {
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const getAutodeletion = async (text, useRag = false, clinicalHistory = '', historicalReport = '') => {
  try {
    const response = await axiosInstance.post('/radcopilot/autodelete/', { 
      text,
      use_rag: useRag,
      clinical_history: clinicalHistory,
      historical_report: historicalReport
    });
    console.log('Autodelete text input:', text);
    console.log('Autodelete response:', response.data.deletion);
    return response.data.deletion;
  } catch (error) {
    console.error('Error getting autodelete:', error.message);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const getChatResponse = async (messages) => {
  try {
    console.log('Sending chat request:', { messages });
    
    const response = await axiosInstance.post('/chat/', { messages });
    
    console.log('Received chat response:', response.data);
    return response.data.answer;
  } catch (error) {
    console.error('Error in chat response:', error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    throw error;
  }
};

export const generateReport = async (text, clinicalHistory = '', historicalReport = '', selectedTemplate = null) => {
  try {
    const response = await axiosInstance.post('/radcopilot/generate-report/', { 
      text,
      clinical_history: clinicalHistory,
      historical_report: historicalReport,
      template_content: selectedTemplate ? selectedTemplate.content : null
    });
    
    console.log('Generate report input:', text);
    console.log('Selected template:', selectedTemplate?.name);
    console.log('Generate report response:', response.data.report);
    
    return response.data.report;
  } catch (error) {
    console.error('Error generating report:', error.message);
    if (error.response) {
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};