import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sidebar from './Sidebar';
import '../styles/RadCoPilot.css';
import { getAutocompletion, getChatResponse, generateReport } from '../api/openai';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const MicIcon = ({ isRecording }) => (
  <svg viewBox="0 0 24 24" fill={isRecording ? "#ff4444" : "#666"}>
    <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"/>
    <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
  </svg>
);

const RadCoPilot = ({ onLogout }) => {
  const [text, setText] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const textareaRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const latestRequestIdRef = useRef(0);
  const [selectedText, setSelectedText] = useState('');
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(false);
  const [ragEnabled, setRagEnabled] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [suggestionStatus, setSuggestionStatus] = useState('idle');
  const [isDictating, setIsDictating] = useState(false);
  const recognition = useRef(null);
  const [speechRecognitionSupported] = useState(!!SpeechRecognition);
  const [chatInput, setChatInput] = useState('');
  const [manualSuggestionRequested, setManualSuggestionRequested] = useState(false);
  const [clinicalHistory, setClinicalHistory] = useState('');
  const [activeTab, setActiveTab] = useState('main');
  const [historicalReport, setHistoricalReport] = useState('');
  const [reportGenerating, setReportGenerating] = useState(false);
  const [showReportGeneratedPopup, setShowReportGeneratedPopup] = useState(false);
  const [originalText, setOriginalText] = useState('');
  const [reportGenerated, setReportGenerated] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showEmptyTextError, setShowEmptyTextError] = useState(false);

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };
  
  const debouncedGetSuggestions = useCallback((newText, forceGet = false) => {
    if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
    }

    latestRequestIdRef.current++;
    const currentRequestId = latestRequestIdRef.current;

    if (newText.trim() !== '') {
        if (autoCompleteEnabled || manualSuggestionRequested) {
            setSuggestionStatus('loading');
        }
        
        debounceTimeoutRef.current = setTimeout(async () => {
            try {
                const currentPosition = textareaRef.current.selectionStart;
                const beforeCursor = newText.slice(0, currentPosition);
                const afterCursor = newText.slice(currentPosition);
                const textWithCursor = `${beforeCursor}|${afterCursor}`;
                
                if (autoCompleteEnabled || forceGet) {
                    const autocompletionResponse = await getAutocompletion(
                        textWithCursor, 
                        ragEnabled,
                        manualSuggestionRequested,
                        clinicalHistory,
                        historicalReport
                    );
                    
                    if (currentRequestId === latestRequestIdRef.current) {
                        if (autocompletionResponse) {
                            setSuggestion(autocompletionResponse);
                            setSuggestionStatus('success');
                        } else {
                            setSuggestion('');
                            setSuggestionStatus('empty');
                        }
                        setManualSuggestionRequested(false);
                    }
                }
            } catch (error) {
                console.error('Error getting suggestions:', error);
                setSuggestionStatus('empty');
                setManualSuggestionRequested(false);
            }
        }, 500);
    } else {
        setSuggestionStatus('idle');
        setManualSuggestionRequested(false);
    }
}, [autoCompleteEnabled, ragEnabled, manualSuggestionRequested, clinicalHistory, historicalReport]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    
    const newPosition = e.target.selectionStart;
    setCursorPosition(newPosition);
    
    textareaRef.current.selectionStart = newPosition;
    textareaRef.current.selectionEnd = newPosition;
    
    setSuggestion('');
    debouncedGetSuggestions(newText);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Alt') {
      e.preventDefault();
      setManualSuggestionRequested(true);
      setSuggestionStatus('loading');
      const currentText = text;
      const beforeCursor = currentText.slice(0, cursorPosition);
      const afterCursor = currentText.slice(cursorPosition);
      const textWithCursor = `${beforeCursor}|${afterCursor}`;
      
      // Make the API call async
      (async () => {
        try {
          const autocompletionResponse = await getAutocompletion(
            textWithCursor, 
            ragEnabled,
            true,  // Force manual to true
            clinicalHistory,
            historicalReport
          );
          
          if (autocompletionResponse) {
            setSuggestion(autocompletionResponse);
            setSuggestionStatus('success');
          } else {
            setSuggestion('');
            setSuggestionStatus('empty');
          }
        } catch (error) {
          console.error('Error getting suggestions:', error);
          setSuggestionStatus('empty');
        } finally {
          setManualSuggestionRequested(false);
        }
      })();
      return;
    }

    if (suggestion) {
      if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        const words = suggestion.split(' ');
        const firstWord = words[0];
        
        const beforeCursor = text.slice(0, cursorPosition);
        const afterCursor = text.slice(cursorPosition);
        
        const newText = beforeCursor + firstWord + ' ' + afterCursor;
        setText(newText);
        
        const newPosition = cursorPosition + firstWord.length + 1;
        setCursorPosition(newPosition);
        
        const remainingSuggestion = words.slice(1).join(' ');
        setSuggestion(remainingSuggestion);

        if (!remainingSuggestion) {
          setSuggestion('');
          debouncedGetSuggestions(newText);
        }
        
        setTimeout(() => {
          textareaRef.current.selectionStart = newPosition;
          textareaRef.current.selectionEnd = newPosition;
        }, 0);
      } else if (e.key === 'Tab') {
        e.preventDefault();
        const beforeCursor = text.slice(0, cursorPosition);
        const afterCursor = text.slice(cursorPosition);
        
        const newText = beforeCursor + suggestion + ' ' + afterCursor;
        setText(newText);
        
        const newPosition = cursorPosition + suggestion.length + 1;
        setCursorPosition(newPosition);
        
        setSuggestion('');
        debouncedGetSuggestions(newText);

        setTimeout(() => {
          textareaRef.current.selectionStart = newPosition;
          textareaRef.current.selectionEnd = newPosition;
        }, 0);
      }
    }
    // Handle other key events
    if (e.key === 'Backspace' && suggestion) {
      e.preventDefault();
      setSuggestion('');
    } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) {
      setSuggestion('');
    }
  };

  // Modify the handleClick function
  const handleClick = (e) => {
    const newPosition = e.target.selectionStart;
    
    // Clear suggestion if cursor moves
    if (newPosition !== cursorPosition) {
      setSuggestion('');
    }
    setCursorPosition(newPosition);
  };

  const handleSelect = (e) => {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    // Clear suggestion if text is selected
    if (selectionStart !== selectionEnd) {
      setSuggestion('');
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      setSelectedText('');
    };
  }, []);

  // Chat interface text selection
  const handleTextSelection = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value.substring(start, end).trim();
      console.log(text);
      if (text && start !== end) {
        setSelectedText(text);
      } else {
        setSelectedText('');
      }
    }
  };

  const handleSelectReport = (report) => {
    setText(report.content || '');
    setClinicalHistory(report.clinical_history || '');
    setHistoricalReport(report.historical_report || '');
    
  };


  useEffect(() => {
    if (showCopiedPopup) {
      const timer = setTimeout(() => {
        setShowCopiedPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showCopiedPopup]);

  useEffect(() => {
    if (showSavePopup) {
      const timer = setTimeout(() => {
        setShowSavePopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSavePopup]);

  const renderTextWithSuggestion = () => {
    if (!suggestion) return text;
    
    const beforeCursor = text.slice(0, cursorPosition);
    const afterCursor = text.slice(cursorPosition);
    
    return (
        <>
            {beforeCursor}
            <span className="suggestion-text">{suggestion}</span>
            {afterCursor}
        </>
    );
  };

  const handleChatStart = async () => {
    // Reset chat messages to empty array
    setChatMessages([]);
    setChatLoading(false);
  };

  const handleChatMessage = async (newMessage, customMessages = null) => {
    let updatedMessages;
    
    if (customMessages) {
      // Use the custom messages array when editing
      updatedMessages = customMessages;
    } else {
      // Normal flow - add the new message to existing messages
      const userMessage = { role: 'user', content: newMessage };
      updatedMessages = [...chatMessages, userMessage];
    }
    
    setChatMessages(updatedMessages);
    setChatLoading(true);

    try {
      const response = await getChatResponse(updatedMessages);
      const assistantMessage = { role: 'assistant', content: response };
      setChatMessages([...updatedMessages, assistantMessage]);
    } catch (error) {
      console.error('Error getting chat response:', error);
    } finally {
      setChatLoading(false);
    }
  };

  const handleClearChat = () => {
    setChatMessages([]);
    setChatLoading(false);
  };

  useEffect(() => {
    if (SpeechRecognition && !recognition.current) {
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      
      recognition.current.onresult = (event) => {
        const currentTranscript = event.results[event.results.length - 1][0].transcript;

        if (event.results[event.results.length - 1].isFinal) {
          let needsSpace = false;
          
          setText(prevText => {
            const currentPosition = textareaRef.current.selectionStart;
            const beforeCursor = prevText.slice(0, currentPosition);
            const afterCursor = prevText.slice(currentPosition);
            
            // Check if we're at the start of a line
            const isNewLine = beforeCursor.endsWith('\n') || beforeCursor === '';
            // Only add space if we're not at the start of a line and don't already have a space
            needsSpace = !isNewLine && beforeCursor.length > 0 && !beforeCursor.endsWith(' ');
            const spacer = needsSpace ? ' ' : '';
            
            return beforeCursor + spacer + currentTranscript + afterCursor;
          });

          // Update cursor position to be after the new transcription
          setTimeout(() => {
            if (textareaRef.current) {
              const newPosition = textareaRef.current.selectionStart + currentTranscript.length + (needsSpace ? 1 : 0);
              textareaRef.current.selectionStart = newPosition;
              textareaRef.current.selectionEnd = newPosition;
              setCursorPosition(newPosition);
            }
          }, 0);
        }
      };
    }
  }, []); // Empty dependency array - only run once on mount

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'm') {
        e.preventDefault();
        
        if (!speechRecognitionSupported) {
          alert('Speech recognition is not supported in your browser. Please use Chrome for this feature.');
          return;
        }

        if (isDictating) {
          recognition.current?.stop();
          // Trigger autocompletion after stopping dictation
          const currentText = textareaRef.current?.value || '';
          debouncedGetSuggestions(currentText);
        } else {
          // Clear any existing suggestion first
          if (suggestion) {
            setSuggestion('');
          }
          
          // Focus the textarea before starting dictation
          textareaRef.current?.focus();
          // Ensure cursor position is maintained
          const currentPosition = textareaRef.current?.selectionStart || 0;
          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.selectionStart = currentPosition;
              textareaRef.current.selectionEnd = currentPosition;
              setCursorPosition(currentPosition);
            }
          }, 0);
          recognition.current?.start();
        }
        setIsDictating(!isDictating);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isDictating, speechRecognitionSupported, suggestion, debouncedGetSuggestions]); // Added dependencies

  const handleCopyToChat = () => {
    if (selectedText) {
      // Append selected text to existing chat input with a space in between
      setChatInput(prevInput => {
        const spacer = prevInput ? ' ' : '';
        return prevInput + spacer + selectedText;
      });
      setSelectedText('');
    }
  };

  const handleScroll = (e) => {
    const textContent = e.target.parentElement.querySelector('.text-content');
    if (textContent) {
      textContent.scrollTop = e.target.scrollTop;
    }
  };

  const handleClinicalHistoryChange = (e) => {
    setClinicalHistory(e.target.value);
  };

  const handleHistoricalReportChange = (e) => {
    setHistoricalReport(e.target.value);
  };

  const handleGenerateReport = async () => {
    // Check if text is empty
    if (!text.trim()) {
      // Show error popup
      setShowEmptyTextError(true);
      // Hide the error popup after 3 seconds
      setTimeout(() => setShowEmptyTextError(false), 3000);
      return;
    }
    
    try {
      // Store the original text before generating the report
      setOriginalText(text);
      setReportGenerating(true);
      setShowReportGeneratedPopup(true); // Show popup immediately with loading state
      
      const generatedReport = await generateReport(
        text,
        clinicalHistory,
        historicalReport,
        selectedTemplate
      );
      
      // Update the text with the generated report
      setText(generatedReport);
      
      // Set reportGenerated to true to show accept/reject buttons
      setReportGenerated(true);
      
      // Keep showing the success message for a moment
      setTimeout(() => setShowReportGeneratedPopup(false), 3000);
    } catch (error) {
      console.error('Error generating report:', error);
      // Show an error message
      setTimeout(() => setShowReportGeneratedPopup(false), 3000);
    } finally {
      setReportGenerating(false);
    }
  };

  // Add handlers for accepting and rejecting the report
  const handleAcceptReport = () => {
    // Keep the generated report (it's already in the text state)
    setReportGenerated(false);
  };

  const handleRejectReport = () => {
    // Restore the original text
    setText(originalText);
    setReportGenerated(false);
  };

  return (
    <div className={`radcopilot-container ${sidebarOpen ? 'shifted' : ''}`}>
      <Sidebar 
        isOpen={sidebarOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        onLogout={onLogout}
        toggleHelp={toggleHelp}
        onSelectReport={handleSelectReport}
        text={text}
        setText={setText}
        setSuggestion={setSuggestion}
        setShowCopiedPopup={setShowCopiedPopup}
        setShowSavePopup={setShowSavePopup}
        selectedText={selectedText}
        autoCompleteEnabled={autoCompleteEnabled}
        setAutoCompleteEnabled={setAutoCompleteEnabled}
        ragEnabled={ragEnabled}
        setRagEnabled={setRagEnabled}
        chatMessages={chatMessages}
        chatLoading={chatLoading}
        onChatStart={handleChatStart}
        onChatMessage={handleChatMessage}
        onClearChat={handleClearChat}
        onCopyToChat={handleCopyToChat}
        chatInput={chatInput}
        setChatInput={setChatInput}
        clinicalHistory={clinicalHistory}
        historicalReport={historicalReport}
        setClinicalHistory={setClinicalHistory}
        setHistoricalReport={setHistoricalReport}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      
      {showHelp && (
        <div className="help-modal">
          <h3>Keyboard Shortcuts</h3>
          <ul>
            <li><strong>Shift + Tab:</strong> Insert the first word of the suggestion</li>
            <li><strong>Tab:</strong> Insert the entire suggestion</li>
            <li><strong>Backspace:</strong> Clear the suggestion</li>
            <li><strong>Alt:</strong> Request new suggestion</li>
          </ul>
          <button onClick={toggleHelp}>Close</button>
        </div>
      )}
      
      <div className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <div className="radcopilot-main">
          <div className="tab-buttons">
            <button 
              className={`tab-button ${activeTab === 'main' ? 'active' : ''}`}
              onClick={() => setActiveTab('main')}
            >
              Current Report
            </button>
            <button 
              className={`tab-button ${activeTab === 'historical' ? 'active' : ''}`}
              onClick={() => setActiveTab('historical')}
            >
              Historical Report
            </button>
          </div>

          {activeTab === 'main' ? (
            <>
              <div className="textarea-wrapper clinical-history">
                <textarea
                  value={clinicalHistory}
                  onChange={handleClinicalHistoryChange}
                  className="rad-textarea"
                  placeholder="Copy and paste your clinical history here..."
                />
              </div>
              <div className="textarea-wrapper">
                <div 
                    className={`suggestion-indicator ${suggestionStatus}`}
                    style={{
                        display: (suggestionStatus === 'idle' || (!autoCompleteEnabled && !manualSuggestionRequested)) 
                            ? 'none' 
                            : 'block'
                    }}
                />
                <div className="text-content">
                    {renderTextWithSuggestion()}
                </div>
                <textarea
                    ref={textareaRef}
                    value={text}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    onSelect={handleSelect}
                    onMouseUp={handleTextSelection}
                    onScroll={handleScroll}
                    className="rad-textarea"
                    placeholder="Write your report here..."
                />
              </div>
              
              <div className="generate-report-button-container">
                {reportGenerated ? (
                  <>
                    <button 
                      className="generate-report-button accept-button"
                      onClick={handleAcceptReport}
                    >
                      Accept Report
                    </button>
                    <button 
                      className="generate-report-button reject-button"
                      onClick={handleRejectReport}
                    >
                      Reject Report
                    </button>
                  </>
                ) : (
                  <button 
                    className="generate-report-button"
                    onClick={handleGenerateReport}
                    disabled={reportGenerating}
                  >
                    {reportGenerating ? (
                      <>
                        <div className="button-spinner"></div>
                        <span>Generating...</span>
                      </>
                    ) : (
                      'Generate Report'
                    )}
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="textarea-wrapper historical-report">
              <textarea
                value={historicalReport}
                onChange={handleHistoricalReportChange}
                className="rad-textarea"
                placeholder="Copy and paste the most recent relevant report here..."
              />
            </div>
          )}
        </div>
        
        {showCopiedPopup && (
            <div className="report-generated-popup">
                Report copied to clipboard!
            </div>
        )}

        {showSavePopup && (
            <div className="report-generated-popup">
                Report saved!
            </div>
        )}

        {showReportGeneratedPopup && (
          <div className="report-generated-popup">
            {reportGenerating ? (
              <>
                <div className="loading-spinner"></div>
                <span>Generating report...</span>
              </>
            ) : (
              <span>Report generated</span>
            )}
          </div>
        )}

        {showEmptyTextError && (
          <div className="empty-text-error-popup">
            You're report is empty!
          </div>
        )}
      </div>
      <div className={`mic-indicator ${isDictating ? 'recording' : ''}`}>
        <MicIcon isRecording={isDictating} />
      </div>
    </div>
  );
};

export default RadCoPilot;
